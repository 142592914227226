.home1{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 1500px;
    height: 100vh;
        

}

.order__id{
    position:absolute;
    top:4px;
    right: 200px;
}

.order__total{
    font-weight: 500;
    text-align: right;
}