.jjjj{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 1500px
}

.hhhh{
    display: flex;
    flex-direction: column; 
    z-index: 1; 
    margin-left: 5px;  
    margin-right: 5px;

}
.orders > h1{
    margin: 30px 0;
}